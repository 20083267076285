
import './App.css';
import React from 'react';


function App() {
 
  return (
      <p className="knul">Hoi knul...</p>
  );
}

export default App;